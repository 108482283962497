
.ContentContainer {
  display: flex;
  flex-direction: column;
  padding-top: 12px;
}

.QAContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0.5rem 1rem;
  background-color: rgb(255, 148, 167);
  border-radius: 0.2rem;
  font-size: 0.8rem;
  font-weight: bold;
  line-height: 1.4;
  border-radius: 20px;
  width: 100%;
  margin-bottom: 8px;
}


.QAHeader {
  text-align: left;
  text-transform: uppercase;
}

.QADescription {
  text-align: left;

  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  word-break: keep-all;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  font-variant-ligatures: none;
  font-family: 'Source Sans Pro';
  font-weight: 400;
  font-style: normal;
  line-height: 1.6;
  text-transform: none;
  letter-spacing: normal;
  font-size: 16.8px;
  color: rgb(0, 0, 0);
  box-sizing: border-box;
}