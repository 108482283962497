.Navigation {
  font-family: 'Cormorant', serif;
  color: rgb(0, 0, 0);
  font-variant-ligatures: none;
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 14px;
  margin: 0px;
  list-style: none;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  flex-flow: row wrap;
  padding: 20px 0px;
}

.NavItem {
  text-decoration: none;
  color: rgb(0, 0, 0);
  margin: 0px 15px;
  padding: 0px;
  z-index: 0;
}

.NavItem:hover {
  cursor: pointer;
  color: rgb(79, 100, 91);
}

.NavItemSelected {
  text-decoration: underline;
  color: rgb(0, 0, 0);
  margin: 0px 15px;
  padding: 0px;
  z-index: 0;
}
