.HeaderContainer {
  display: flex;
  flex-direction: column;
  font-variant-ligatures: none;
  font-weight: 400;
  font-style: normal;
  line-height: 1.6;
  text-transform: none;
  letter-spacing: normal;
  font-size: 16.8px;
  color: rgb(0, 0, 0);
  background-color: transparent;
  background-image: url('./header.png'), none;
  background-position: center top, left top;
  background-repeat: no-repeat, repeat;
  text-align: center;
  background-size: 1600px, auto;
  padding: 100px 0px 0px;
}

.SmallHeader {
  font-family: 'Brandon Grotesque Regular';
  font-weight: 400;
  font-style: normal;
  line-height: 1.6;
  text-transform: none;
  letter-spacing: normal;
  font-size: 16.8px;
  color: rgb(0, 0, 0);
  font-variant-ligatures: none;
}

.SmallNameContainer {
  font-variant-ligatures: none;
  display: flex;
  flex-flow: row wrap;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 10px 0px;
  flex-wrap: nowrap;
}

.SmallHeaderSpacer {
  text-align: center;
  display: inline-block;
  text-indent: -10000px;
  background-image: url('./headersm.png');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 75px !important;
  height: 75px !important;
}

.SmallName {
  text-align: right;
  flex: 0 1 auto;
  margin-bottom: 0px;
}

.headerImage {
  position: absolute;
}

.nameContainer {
  margin: 0px auto;
  max-width: 1170px;
  box-sizing: border-box;
  padding-top: 30px;
}

.Names {
  font-variant-ligatures: none;
  text-align: center;
  font-family: 'Cormorant', serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: rgb(0, 0, 0);
  margin: 0px;
  font-size: 62.4px;
}

.and {
  font-variant-ligatures: none;
  text-align: center;
  font-family: 'Cormorant', serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: rgb(0, 0, 0);
  font-size: 27.2px;
}

.Keely {
  font-variant-ligatures: none;
  text-align: center;
  font-family: 'Cormorant', serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: rgb(0, 0, 0);
  margin: 0px;
  font-size: 62.4px;
}

.SmallDividerTop {
  font-style: normal;
  font-variant-ligatures: none;
  background-image: url('./divider.png') !important;
  background-position: center center !important;
  background-repeat: repeat no-repeat !important;
  background-size: auto 50px !important;
  color: #000000 !important;
  font-family: 'Brandon Grotesque Regular' !important;
  font-size: 16.8px !important;
  font-weight: 400 !important;
  height: 50px !important;
  letter-spacing: normal !important;
  line-height: 1.6 !important;
  text-transform: none !important;
}

.HeaderLabelContainer {
  font-weight: 400;
  font-style: normal;
  line-height: 1.6;
  text-transform: none;
  letter-spacing: normal;
  font-size: 16.8px;
  color: rgb(0, 0, 0);
  font-variant-ligatures: none;
  margin-bottom: 30px;
  text-align: center;
}

.HeaderLabel {
  font-family: 'Figtree', sans-serif;
  font-variant-ligatures: none;
  text-align: center;
  font-weight: 400;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 4px;
  color: rgb(0, 0, 0);
  font-size: 50.4px;
}
