.ContentHeaderContainer {
  -webkit-font-smoothing: antialiased;
  font-family: Figtree, sans-serif;
  color: #000;
  font-size: 16.8px;
  font-style: normal;
  font-variant-ligatures: none;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.6;
  text-transform: none;
  margin-bottom: 40px;
  text-align: center;
}

.HeaderContainer {
  -webkit-font-smoothing: antialiased;
  font-family: Figtree, sans-serif;
  text-transform: none;
  color: #000;
  font-size: 50.4px;
  font-style: normal;
  font-variant-ligatures: none;
  font-weight: 400;
  letter-spacing: 4px;
  line-height: 1;
  margin: 0;
  text-align: center;
}

.ContentHeaderText {
  -webkit-font-smoothing: antialiased;
  font-family: Figtree, sans-serif;
  text-transform: none;
  color: #000;
  font-size: 50.4px;
  font-style: normal;
  font-variant-ligatures: none;
  font-weight: 400;
  letter-spacing: 4px;
  line-height: 1;
  margin: 0;
  text-align: center;
}
