.DividerImage {
  width: 200px;
  height: 50px;
}

.DividerContainer {
  display: flex;
  align-items: center;
}

/* media queries */
@media (max-width: 767px) {
  .DividerContainer {
    flex-direction: row;
  }
}
@media (max-width: 500px) {
  .DividerContainer {
    flex-direction: column;
  }
}