.App {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.6;
  text-transform: none;
  letter-spacing: normal;
  font-size: 16.8px;
  color: rgb(0, 0, 0);
  font-variant-ligatures: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.SectionHeader {
  font-variant-ligatures: none;
  text-align: center;
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  letter-spacing: 4px;
  color: rgb(0, 0, 0);
  margin: 0px;
  font-size: 50.4px;
}
