.MainContentContainer {
  font-family: 'Figtree', sans-serif;
  font-variant-ligatures: none;
  font-weight: 400;
  font-style: normal;
  line-height: 1.6;
  text-transform: none;
  letter-spacing: normal;
  font-size: 16.8px;
  color: rgb(0, 0, 0);
  background-color: rgb(246, 213, 202);
  background-image: none;
  background-repeat: repeat;
  background-position: left top;
  padding: 80px 0px;
}
