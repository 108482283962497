
.GiftImage {
  width: 474px;
}


/* media queries */
@media (max-width: 474px) {
  .GiftImage {
    width: 100%;
  }
}