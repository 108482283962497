@font-face {
  font-family: 'AdoreYou';
  src: url('./fonts/AdoreYou/AdoreYou.ttf'), format('truetype');
}

@font-face {
  font-family: 'Brandon Grotesque Regular';
  src: url('./fonts/BrandonGrotesque-Regular-Regular.otf'), format('opentype');
}

@font-face {
  font-family: 'Yadon Medium';
  src: url('./fonts/Yadon-Medium.otf'), format('opentype');
}

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic');

@import url('https://fonts.googleapis.com/css2?family=Noticia+Text&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Figtree:ital@1&family=Noticia+Text&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@300;400&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
