.SectionContainer {
  font-family: 'Brandon Grotesque Regular';
  font-weight: 400;
  font-style: normal;
  line-height: 1.6;
  text-transform: none;
  letter-spacing: normal;
  font-size: 16.8px;
  color: rgb(0, 0, 0);
  font-variant-ligatures: none;
  width: 100%;
  margin: 20px 20px 2rem;
  display: flex;
  text-align: left;
  flex-direction: row;
}

.LeftContent {
  font-family: 'Brandon Grotesque Regular';
  font-weight: 400;
  font-style: normal;
  line-height: 1.6;
  text-transform: none;
  letter-spacing: normal;
  font-size: 16.8px;
  color: rgb(0, 0, 0);
  font-variant-ligatures: none;
  text-align: left;
  flex: 0 0 50%;
  margin-bottom: 0px;
  margin-right: 20px;
}

.RightContent {
  font-family: 'Brandon Grotesque Regular';
  font-weight: 400;
  font-style: normal;
  line-height: 1.6;
  text-transform: none;
  letter-spacing: normal;
  font-size: 16.8px;
  color: rgb(0, 0, 0);
  font-variant-ligatures: none;
  flex: 1 1 0%;
  text-align: left;
}

.AddPersonButton {
  margin-top: 8px;
  margin-bottom: 8px;
}