.SectionText {
  font-family: 'Source Sans Pro';
  font-weight: 400;
  line-height: 1.6;
  font-size: 16.8px;
  color: rgb(0, 0, 0);
  text-align: center;
}

.OurStoryImage {
  width: 350px;
  height: 525px;
}

.LeftCell {
  display: flex;
  flex: 50%;
  padding: 10px;
}

.RightCell {
  display: flex;
  padding: 10px;
  justify-content: center;;
}

@media only screen and (max-width: 850px) {
  .StoryContentContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 10px;
  }

  .RightCell {
    width: 100%;
  }
}

@media only screen and (min-width: 850px) {
  .StoryContentContainer {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
  }
}
