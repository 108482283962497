.SplitContentSection {
  box-sizing: border-box;
  display: flex;
  word-break: keep-all;
  flex-flow: row wrap;
  justify-content: center;
}

.InnerContentLeft {
  direction: ltr;
  -webkit-font-smoothing: antialiased;

  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.InnerContentRight {
  direction: ltr;
  box-sizing: border-box;
  position: relative;
}
