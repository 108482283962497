.ContentContainerBase {
  margin: 0px auto;
  max-width: 1170px;
  box-sizing: border-box;
}

.ContentContainerInner {
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
